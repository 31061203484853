<template>
	<FormProductAdditionalFee
		:is-submitting="isCreating"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import FormProductAdditionalFee from '@/components/FormProductAdditionalFee.vue';
import { priceToAPI } from '../assets/js/helpers';
import { SHIPPING_ADDITIONAL_FEE_TYPE } from '../enums/shippings';

export default {
	name: 'ShippingProductAdditionalFeeCreate',
	components: {
		FormProductAdditionalFee,
	},
	validations: {
		fee: {
			required,
		},
	},
	computed: {
		...mapState('additionalFees', {
			isCreating: (state) => state.additionalFees[SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT].create.isCreating,
		}),
	},
	methods: {
		...mapActions({
			postAdditionalFee: 'additionalFees/postAdditionalFee',
		}),
		async handleSubmit(fee, skuList) {
			const shippingMethodId = this.$route.params.shippingMethodId;
			const params = {
				fee: priceToAPI(fee),
				type: SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT,
				item_ids: skuList,
			};

			try {
				await this.postAdditionalFee({
					type: SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT,
					id: shippingMethodId,
					params,
				});

				this.$router.push({ name: 'ShippingSetting' });
			} catch {
				// Do nothing
			}
		},
	},
};
</script>


<style lang="scss" scoped>
	.form-group {
		label {
			flex: 1;
			max-width: rem(160);
			margin-bottom: 0;
			position: relative;
			top: 8px;
		}

		.to {
			@include typo-body-2;

			position: relative;
			top: 8px;
			margin: 0 rem(24);
		}


		// .form-group-cart
		&-cart {
			margin-bottom: rem(32);
		}

		::v-deep .input-group {
			max-width: rem(140);
		}
	}

	.btn-submit {
		min-width: auto;
	}
</style>
